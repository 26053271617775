const DATA_TRIGGER = 'data-dropdown-trigger';
const DATA_DROPDOWN = 'data-dropdown';
const SELECTED_CLASS = 'droplab-item-selected';
const ACTIVE_CLASS = 'droplab-item-active';
const IGNORE_CLASS = 'droplab-item-ignore';
// Matches `{{anything}}` and `{{ everything }}`.
const TEMPLATE_REGEX = /\{\{(.+?)\}\}/g;

export {
  DATA_TRIGGER,
  DATA_DROPDOWN,
  SELECTED_CLASS,
  ACTIVE_CLASS,
  TEMPLATE_REGEX,
  IGNORE_CLASS,
};
